.all-stores-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
}

.row>* {
    padding-right: 0!important;
    padding-left: 0!important;
}
