input[type="range"]::-webkit-slider-thumb {
    /*width: 25px!important;*/
    /*height: 25px!important;*/
    background: #FFFFFF!important;
    /* Color Mandao */
    border: 1px solid #FFBF00!important;
    align-items: center;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
    /*width: 25px!important;*/
    /*height: 25px!important;*/
    background: #FFFFFF!important;
    /* Color Mandao */
    border: 1px solid #FFBF00!important;
    align-items: center;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
    /*width: 25px!important;*/
    /*height: 25px!important;*/
    background: #FFFFFF!important;
    /* Color Mandao */
    border: 1px solid #FFBF00!important;
    align-items: center;
}

.circle-up {
    width: 20px;
    height: 20px;
    color: white;
    background: black;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.range-label {
    font-family: 'Roboto Condensed', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}