.is-close {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;

    /* Rojo */

    color: #FF0531;
}