.right-arrow {
    width: 24px;
    height: 24px;
}
@media (max-width: 1340px) {
    .banners {
        width: 380px!important;
    }
}
@media (max-width: 1250px) {
    .banners {
        width: 350px!important;
    }
}
@media (max-width: 1150px) {
    .banners {
        width: 325px!important;
    }
}
@media (max-width: 1100px) {
    .banners {
        width: 315px!important;
    }
}
@media (max-width: 1030px) {
    .banners {
        width: 310px!important;
    }
}
@media (max-width: 1000px) {
    .banners {
        width: 400px!important;
    }
}
@media (max-width: 900px) {
    .banners {
        width: 350px!important;
    }
}
@media (max-width: 780px) {
    .banners {
        width: 325px!important;
    }
}
@media (max-width: 728px) {
    .banners {
        width: 315px!important;
    }
}
@media (max-width: 700px) {
    .banners {
        width: 305px!important;
    }
}
@media (max-width: 680px) {
    .banners {
        width: 290px!important;
    }
    .banners-title {
        font-size: 20px!important;
    }
}
@media (max-width: 650px) {
    .banners {
        width: 270px!important;
    }
}
@media (max-width: 600px) {
    .banners {
        width: 230px!important;
    }
}
@media (max-width: 530px) {
    .banners {
        width: 200px!important;
    }
    .banners-title {
        font-size: 18px!important;
        line-height:20px!important;
    }
}
@media (max-width: 464px) {
    .banners {
        width: 170px!important;
    }
}
@media (max-width: 400px) {
    .banners {
        width: 150px!important;
    }
    .right-arrow {
        width: 12px;
        height: 12px;
    }
}
@media (max-width: 362px) {
    .banners {
        width: 135px!important;
    }
}
