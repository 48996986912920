@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Coda:wght@400;800&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import "react-alice-carousel/lib/alice-carousel.css";
@import "react-phone-number-input/style.css";

body {
    margin: 0;
    font-family: 'Roboto Condensed', serif;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.background-mandao-color {
    background: #FFBF00
}

.mandao-color {
    color: #FFBF00
}

.border-solid-white {
    border: #E2E2E2 solid 1px;
}

.input-otp-verification {
    width: 4rem !important;
    height: 4rem !important;
    margin: 0.5rem;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
}

.input-otp-verification:focus {
    color: #212529;
    background-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0;
}

.input-otp-verification.invalid {
    border: 1px solid #FA0606FF;
}

input:focus-visible, input:focus, select:focus {
    outline: none !important;
}

.form-select:focus {
    box-shadow: none;
    border-color: #000;
}

.cursor-pointer {
    cursor: pointer;
}

.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}

.react-multiple-carousel__arrow {
    border: 1px black;
    background: #ffffff;
    min-width: 31.21px;
    min-height: 31.21px;
}

.react-multiple-carousel__arrow:hover {
    background: #FFFFFF86;
    border: 1px gray;
}

.react-multiple-carousel__arrow::before {
    color: black;
    font-size: 13px;
    font-weight: bolder;
}