.filter-order-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.filter-order-options {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.location-icon {
    width: 13px;
    height: 15px;
}

.star-icon {
    width: 17px;
    height: 16px;
}

.clock-icon {
    width: 17px;
    height: 17px;
}

.filter-radio {
    width: 17px;
    height: 17px;
}
.filter-checkbox {
    width: 19px;
    height: 19px;
}

input[type="checkbox"] {
    accent-color: #FFBF00;
}

input[type="radio"] {
    appearance: none;
    width: 17px;
    height: 17px;
    border: 1px solid #000000;
    border-radius: 50%;
    background-clip: content-box;
    padding: 3px;
}

input[type="radio"]:checked {
    background-color: #FFBF00;
}

/*input[type=range]::-webkit-slider-runnable-track {*/
/*    !*width: 100%;*!*/
/*    height: 2px;*/
/*    !*cursor: pointer;*!*/
/*    !*animate: 0.2s;*!*/
/*    !*box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*!*/
/*    !*background: #3071a9;*!*/
/*    !*border-radius: 1.3px;*!*/
/*    !*border: 0.2px solid #010101;*!*/
/*}*/